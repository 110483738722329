
                    import worker from "../../src/js/perspective.worker.js";
                    async function get_worker_code() {
                        const url = new URL(worker, import.meta.url);
                        const req = await fetch(url);
                        const code = await req.text();
                        return code;
                    };

                    function make_host(a, b) {
                        function addEventListener(type, callback) {
                            a.push(callback);
                        }

                        function postMessage(msg) {
                            if (Object.keys(msg).length > 0) {
                                for (const listener of b) {
                                    listener({data: msg});
                                }
                            }
                        }

                        return {
                            addEventListener,
                            postMessage,
                            location: {href: ""}
                        }
                    }

                    function run_single_threaded(code) {
                        let f = Function("const self = arguments[0];" + code);
                        const workers = [];
                        const mains = [];
                        f(make_host(workers, mains));
                        return make_host(mains, workers);
                    }

                    const code_promise = get_worker_code();
                    export const initialize = async function () {
                        const code = await code_promise;
                        if (window.location.protocol.startsWith("file") && !window.isElectron) {
                            console.warn("file:// protocol does not support Web Workers");
                            return run_single_threaded(code);
                        }

                        try {
                            const blob = new Blob([code], {type: 'application/javascript'});
                            const url = URL.createObjectURL(blob);
                            return new Worker(url, {type: "module"});
                        } catch (e) {
                            console.warn("Failed to instantiate worker, falling back to single-threaded runtime", e);
                            return run_single_threaded(code);
                        }
                    };

                    export default initialize;
                